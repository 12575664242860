<template>
  <div class="tasks-container">
    <Head />
    <div class="tasks-content">
      <div class="tab-buttons">
        <button
          v-for="tab in ['Tasks', 'Quests', 'Frens']"
          :key="tab"
          :class="['tab-button', { active: activeTab === tab }]"
          @click="setActiveTab(tab)"
        >
          {{ tab }}
        </button>
      </div>

      <div v-if="loading" class="loading-message">Loading tasks...</div>
      <div v-else-if="error" class="error-message">{{ error }}</div>
      <div v-else-if="sortedTasks.length === 0" class="no-tasks-message">No tasks available</div>
      <div v-else class="tasks-list">
        <div v-for="task in sortedTasks" :key="task.task_id" class="task-card">
          <div class="task-info">
            <component :is="getIconComponent(task.icon)" class="task-icon" />
            <div class="task-details">
              <div class="task-title">{{ task.title }}</div>
              <div class="task-reward">
                {{ getRewardText(task) }}
              </div>
              <div v-if="task.type === 'Frens' || task.type === 'ADS'" class="task-progress">
                {{ getTaskProgress(task) }} / {{ task.type === 'Frens' ? task.required_refs : task.required_ads }}
              </div>
            </div>
          </div>
          <button 
            v-if="!isTaskCompleted(task)"
            :class="['task-button', { 
              'loading': task.status === 'Checking',
              'check': task.status === 'Check',
              'disabled': isTaskDisabled(task)
            }, {'ads': task.type === 'ADS'}]"
            @click="handleTaskAction(task)"
            :disabled="task.status === 'Checking' || isTaskDisabled(task)"
          >
            <span v-if="task.status !== 'Checking'">{{ getTaskButtonText(task) }}</span>
            <div v-else class="loading-spinner"></div>
          </button>
          <div v-else class="task-completed">Completed</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import Head from './Head.vue'
import { PlayCircle, Trophy, Rocket, Send, Gift, Coins, Smile } from 'lucide-vue-next'
import axios from 'axios'

export default {
  name: 'Tasks',
  components: {
    Head,
    PlayCircle,
    Trophy,
    Rocket,
    Send,
    Gift,
    Coins,
    Smile
  },
  setup() {
    const store = useStore()
    const activeTab = ref('Tasks')
    const tasks = ref([])
    const loading = ref(true)
    const error = ref(null)

    const loadTasks = async () => {
      try {
        loading.value = true
        error.value = null
        const response = await axios.get('/tasks', {
          params: { user_id: store.state.userId }
        })
        tasks.value = response.data.tasks.map(task => ({
          ...task,
          status: task.completed ? 'Completed' : 'Start'
        }))
        console.log('Loaded tasks:', tasks.value) // Добавим для отладки
      } catch (err) {
        console.error('Ошибка при загрузке задач:', err)
        error.value = 'Не удалось загрузить задачи. Пожалуйста, попробуйте позже.'
      } finally {
        loading.value = false
      }
    }

    onMounted(loadTasks)

    watch(activeTab, () => {
      console.log('Active tab changed:', activeTab.value) // Добавим для отладки
    })

    const sortedTasks = computed(() => {
      console.log('Sorting tasks for tab:', activeTab.value)
      return tasks.value
        .filter(task => {
          if (activeTab.value === 'Tasks') {
            return task.type === 'Tasks' || task.type === 'ADS';
          }
          return task.type.toLowerCase() === activeTab.value.toLowerCase();
        })
        .sort((a, b) => {
          if (a.completed === b.completed) return 0;
          return a.completed ? 1 : -1;
        });
    });

    const setActiveTab = (tab) => {
      activeTab.value = tab
    }

    const isTaskCompleted = (task) => {
      if (task.type === 'ADS') {
        if (task.title === 'Watch the Full AD') {
          return (task.progress || 0) >= task.required_ads;
        }
        return store.state.watchedAds >= task.required_ads;
      }
      return task.completed;
    };

    const isTaskDisabled = (task) => {
      if (task.type === 'Frens') {
        return store.state.refCount < task.required_refs;
      }
      if (task.title === 'Ads watching hero (300 ads done)') {
        const watchFullAdTask = tasks.value.find(t => t.title === 'Watch the Full AD');
        return !watchFullAdTask || getTaskProgress(watchFullAdTask) < watchFullAdTask.required_ads;
      }
      return false;
    };

    const getTaskButtonText = (task) => {
      if (task.type === 'ADS') {
        return 'Watch';
      }
      return task.status === 'Start' ? 'Start' : 'Check';
    };

    const showAd = () => {
      return new Promise((resolve, reject) => {
        if (window.Adsgram) {
          const AdController = window.Adsgram.init({ blockId: "4015" });
          AdController.show()
            .then((result) => {
              console.log('Реклама просмотрена', result);
              resolve(true);
            })
            .catch((result) => {
              console.error('Ошибка показа рекламы', result);
              reject(false);
            });
        } else {
          console.error('Adsgram не инициализирован');
          reject(false);
        }
      });
    };

    const handleTaskAction = async (task) => {
      if (isTaskCompleted(task) || isTaskDisabled(task)) return;

      if (task.type === 'ADS') {
        try {
          const adWatched = await showAd();
          if (adWatched) {
            if (task.title === 'Watch the Full AD') {
              task.progress = (task.progress || 0) + 1;
              store.commit('incrementWatchedAds');
            }
            task.status = 'Check';
          }
        } catch (error) {
          console.error('Ошибка при показе рекламы:', error);
          return;
        }
      } else if (task.status === 'Start') {
        if (task.link) {
          window.open(task.link, '_blank');
        }
        task.status = 'Check';
      } else if (task.status === 'Check') {
        task.status = 'Checking';
        try {
          const response = await axios.post('/complete_task', {
            user_id: store.state.userId,
            task_id: task.task_id
          });
          if (response.data.success) {
            task.completed = true;
            task.status = 'Completed';
            store.commit('updateUserData', response.data.user);
            store.commit('setLocalBalance', response.data.user.balance);
          } else {
            task.status = 'Check';
          }
        } catch (error) {
          console.error('Ошибка при выполнении задачи:', error)
          task.status = 'Check'
        }
      }
    }

    const getIconComponent = (iconName) => {
      const iconMap = {
        PlayCircle, Trophy, Rocket, Send, Gift, Coins, Smile
      }
      return iconMap[iconName] || Send
    }

    const getRewardText = (task) => {
      let rewardText = ''
      if (task.reward_tokens) rewardText += `+${task.reward_tokens} `
      if (task.reward_hour) rewardText += `+${task.reward_hour}/час `
      if (task.reward_tap) rewardText += `+${task.reward_tap}/клик `
      return rewardText.trim()
    }

    const getTaskProgress = (task) => {
      if (task.type === 'ADS') {
        if (task.title === 'Watch the Full AD') {
          return task.progress || 0;
        }
        return store.state.watchedAds;
      } else if (task.type === 'Frens') {
        return store.state.refCount;
      }
      return 0;
    }

    return {
      activeTab,
      sortedTasks,
      setActiveTab,
      handleTaskAction,
      getIconComponent,
      getRewardText,
      loading,
      error,
      store,
      isTaskCompleted,
      isTaskDisabled,
      getTaskButtonText,
      getTaskProgress,
      showAd,
    }
  }
}
</script>

<style scoped>
.tasks-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #c9aa71;
  color: black;
}

.tasks-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 90px; /* Отступ для шапки */
  padding-bottom: 60px; /* Отступ для нижнего меню */
}

.tab-buttons {
  display: flex;
  background-color: #4b5563;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.tab-button {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  color: #d1d5db;
  background: none;
  border: none;
  cursor: pointer;
}

.tab-button.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 0.5rem;
}

.tasks-list {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для Internet Explorer и Edge */
  margin-bottom: 1rem;
  max-height: calc(100vh - 220px); /* Высота с учетом отступов */
}

.tasks-list::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}

.task-card {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.task-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #4CAF50;
  flex-shrink: 0;
}

.task-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.task-title {
  font-size: 0.875rem;
  font-weight: 600;
}

.task-reward {
  font-size: 0.75rem;
  color: #6b7280;
}

.task-limit {
  color: #4CAF50;
}

.task-button {
  width: 80px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.task-button:not(.loading):not(.check):not(.disabled) {
  background-color: #4CAF50;
  color: white;
}

.task-button.loading {
  position: relative;
  color: transparent;
  background-color: #4CAF50;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.task-button:hover {
  background-color: #45a049;
}

.task-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-button.loading:hover {
  background-color: #45a049;
}

.task-button.loading:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-button.check:hover {
  background-color: #FF8C00;
}

.task-button.check:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.task-completed {
  color: #4CAF50;
  font-weight: bold;
  font-size: 14px;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid #4CAF50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.task-progress {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.1rem;
}

.task-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.task-button.disabled:hover {
  background-color: #cccccc;
}

.task-button.start, .task-button.check.ads, .task-button.check.ads:hover {
  background-color: #4CAF50;
  color: white;
}

.task-button.check:not(.ads) {
  background-color: #FFA500;
  color: white;
}
</style>