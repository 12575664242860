<template>
  <div class="frens-container">
    <h1 class="title">FRENS</h1>
    <p class="subtitle">You found {{ refCount }} SKUFFS</p>
    
    <div class="entries-container">
      <div class="entry" v-for="(entry, index) in friends" :key="entry.user_id">
        <div class="entry-content">
          <div class="entry-left">
            <Trophy v-if="index < 3" :class="['trophy', getTrophyColor(index)]" />
            <span v-else class="entry-number">{{ index + 1 }}</span>
            <span class="entry-name">{{ entry.username }}</span>
          </div>
          <span class="entry-amount">${{ entry.balance }}</span>
        </div>
      </div>
    </div>
    
    <div class="notification" :class="{ 'show': showNotification }">
      Реферальная ссылка скопирована!
    </div>
    
    <div class="button-container">
      <button class="invite-button" @click="inviteFriend">Invite</button>
      <button class="copy-button" @click="copyReferralLink"><Copy class="copy-icon" /></button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { Trophy, Copy } from 'lucide-vue-next'

export default {
  name: 'Frens',
  components: {
    Trophy,
    Copy
  },
  setup() {
    const store = useStore()
    const showNotification = ref(false)

    const refCount = computed(() => store.getters.getRefCount)
    const friends = computed(() => store.getters.getFriends)
    const referralCode = computed(() => store.getters.getReferralCode)

    const getTrophyColor = (index) => {
      const colors = ["trophy-gold", "trophy-silver", "trophy-bronze"]
      return colors[index] || ""
    }

    const inviteFriend = () => {
      console.log("inviteFriend function called");
      const refLink = `t.me/skufftokenbot/?start=${referralCode.value}`
      console.log("Referral link:", refLink);
      
      if (refLink) {
        const message = "Welcome to our game! Join and earn with us!";
        const telegramLink = `https://t.me/share/url?url=${encodeURIComponent(refLink)}&text=${encodeURIComponent(message)}`;
        console.log("Telegram Link: ", telegramLink);
        window.open(telegramLink, '_blank');
      } else {
        console.error('Referral link is not available');
      }
    }

    const copyReferralLink = () => {
      const refLink = `t.me/skufftokenbot/?start=${referralCode.value}`
      navigator.clipboard.writeText(refLink).then(() => {
        showNotification.value = true
        setTimeout(() => {
          showNotification.value = false
        }, 3000)
      }, (err) => {
        console.error('Failed to copy text:', err)
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.showAlert('Failed to copy link')
        } else {
          alert('Failed to copy link')
        }
      })
    }

    onMounted(async () => {
      await store.dispatch('loadFriends')
    })

    return {
      refCount,
      friends,
      getTrophyColor,
      inviteFriend,
      copyReferralLink,
      showNotification
    }
  }
}
</script>

<style scoped>
.frens-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #c9aa71;
  padding: 1rem;
  color: black;
  position: relative;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
  color: white;
}

.subtitle {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
  color: white;
}

.entries-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
  max-height: calc(13 * (0.75rem * 2 + 1rem)); /* Высота 7 карточек */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.entries-container::-webkit-scrollbar {
  display: none;
}

.entry {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.entry-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entry-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.entry-number {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.entry-name, .entry-amount {
  font-size: 1.125rem;
}

.trophy {
  width: 1.25rem;
  height: 1.25rem;
}

.trophy-gold {
  color: #ffd700;
}

.trophy-silver {
  color: #c0c0c0;
}

.trophy-bronze {
  color: #cd7f32;
}

.button-container {
  position: fixed;
  bottom: 70px; /* Отступ от нижнего края для NavBar */
  left: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
  background-color: #c9aa71;
  z-index: 10;
}

.invite-button, .copy-button {
  background-color: white;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.invite-button {
  flex-grow: 1;
}

.copy-button {
  padding: 0.5rem;
}

.invite-button:hover, .copy-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.copy-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.notification {
  position: fixed;
  bottom: 130px; /* Располагаем уведомление над кнопками */
  left: 1rem;
  right: 1rem;
  background-color: #4CAF50; /* Зеленый цвет */
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Чтобы уведомление не мешало взаимодействию с элементами под ним */
}

.notification.show {
  opacity: 1;
}
</style>