<template>
  <div id="app">
    <LoadingScreen v-if="isLoading" @loading-complete="onLoadingComplete" />
    <template v-else>
      <NavBar v-if="isUserLoaded" />
      <router-view v-if="isUserLoaded" />
      <div v-else-if="error">Ошибка: {{ error }}</div>
      <div v-else>Загрузка...</div>
    </template>
    <!-- Добавляем скрытый элемент для TON Connect -->
    <div id="ton-connect" style="display: none;"></div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import LoadingScreen from './components/LoadingScreen.vue'
import NavBar from './components/NavBar.vue'
import { TonConnectUI } from '@tonconnect/ui';

export default {
  name: 'App',
  components: {
    LoadingScreen,
    NavBar
  },
  setup() {
    const store = useStore()
    const isUserLoaded = ref(false)
    const isLoading = ref(true)
    const error = ref(null)
    let updateInterval
    let tonConnector

    const initializeTonConnect = () => {
      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: 'https://ff-crypto.com/tonconnect-manifest.json',
        buttonRootId: 'ton-connect'
      });

      // Подписываемся на изменения статуса подключения
      tonConnectUI.onStatusChange(walletInfo => {
        if (walletInfo) {
          console.log('Кошелек подключен:', walletInfo);
          store.commit('setWalletInfo', walletInfo);
        } else {
          console.log('Кошелек отключен');
          store.commit('setWalletInfo', null);
        }
      });

      // Сохраняем экземпляр connector в хранилище для использования в других компонентах
      store.commit('setTonConnector', tonConnectUI);
    };

    const preloadImages = async () => {
      const images = [
        require('@/static/background.png'),
        require('@/static/skuff.png'),
        require('@/static/puzo.png')
      ]
      
      await Promise.all(images.map(src => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = src
          img.onload = resolve
          img.onerror = reject
        })
      }))
    }

    const onLoadingComplete = () => {
      isLoading.value = false
    }

    const initializeApp = async () => {
      let userId;
      try {
        if (window.Telegram && window.Telegram.WebApp) {
          console.log('Telegram WebApp обнаружен');
          window.Telegram.WebApp.ready();
          window.Telegram.WebApp.expand();
          const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
          userId = initDataUnsafe.user ? initDataUnsafe.user.id : null;
        } else {
          console.log('Telegram WebApp не обнаружен, используем URL параметры');
          const urlParams = new URLSearchParams(window.location.search);
          userId = urlParams.get('user_id');
        }

        console.log('User ID:', userId);
        if (userId) {
          store.commit('setUserId', userId);
          await store.dispatch('loadUserData');
          
          // Инициализация Adsgram
          if (window.Adsgram) {
            const AdController = window.Adsgram.init({ blockId: "4015" });
            store.commit('setAdController', AdController);
          } else {
            console.error('Adsgram SDK не загружен');
          }

          // Инициализация TON Connect
          initializeTonConnect();

          await preloadImages();
          isUserLoaded.value = true;
        } else {
          error.value = 'ID пользователя не найден';
        }
      } catch (e) {
        console.error('Ошибка при инициализации:', e);
        error.value = 'Ошибка при инициализации приложения';
      }
    }

    onMounted(async () => {
      await initializeApp();
      // Устанавливаем таймаут на 5 секунд для имитации минимального времени загрузки
      setTimeout(() => {
        isLoading.value = false;
      }, 5000);
    })

    onMounted(() => {
      updateInterval = setInterval(() => {
        store.dispatch('updateAvailableTaps')
      }, 1000) // Обновляем каждую секунду
    })

    onUnmounted(() => {
      clearInterval(updateInterval)
    })

    return {
      isUserLoaded,
      isLoading,
      error,
      onLoadingComplete
    }
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none; /* Запрещаем выделение элементов */
  touch-action: none; /* Запрещаем зум и скролл */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
